/* body {
  background: #eaecfa;
} */

.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 900;
  color: #DF6E46;
  letter-spacing: 0.2em;
}

.loader::before, .loader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #DF6E46;
  position: absolute;
  animation: load 0.7s infinite alternate ease-in-out;
}

.loader::before {
  top: 0;
}

.loader::after {
  bottom: 0;
}

@keyframes load {
  0% { left: 0; height: 25px; width: 15px; }
  50% { height: 8px; width: 40px; }
  100% { left: 235px; height: 25px; width: 15px; }
}


.swal-confirm-button {
    background-color: #93AB4F !important; /* Background color for the confirmation button */
    width: 100px;
    height: 50px;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 16px;
}
@keyframes blink {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  
  .blink-text {
    animation: blink 1s infinite;
  }
  